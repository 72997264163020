
class Main {
  constructor() {
    console.log('Main service');
  }

  hideLoader()  {
    document.querySelector('body')?.classList.remove('no-overflow');
    document.querySelector('.loaderContainer')?.classList.add('loader--hide');
    setTimeout(() => {
      document.querySelector('.loaderContainer')?.classList.add('loader--hidden');
    }, 200);
  }

  showLoader()  {
    document.querySelector('body')?.classList.add('no-overflow');
    document.querySelector('.loaderContainer')?.classList.remove('loader--hide');
    setTimeout(() => {
      document.querySelector('.loaderContainer')?.classList.remove('loader--hidden');
    }, 200);
  }
}
export default new Main();
