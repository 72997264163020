import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { AppRoutes } from './components';
// import { ThemeProvider } from 'react-bootstrap';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { itIT } from '@mui/material/locale';
import { registerLicense, L10n, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RhUH5WdHxWQGBcUE0=');

setCulture('it');
setCurrencyCode('EUR');
L10n.load({
  'it-IT': {
    'PdfViewer': {
      Cancel: 'Annulla',
      'Download file': 'Scarica file',
      'Download': 'Scarica',
      'Print file': 'Stampa file',
      'Submit': 'Invia',
      'Form': 'Modulo',
      'Create': 'Firma',
      'Draw-hand Signature': 'Firma',
      'Clear': 'Cancella',
    },
    'uploader': {
      'Browse': 'Sfoglia...',
      'Clear': 'Cancella',
      'Upload': 'Carica',
      'dropFilesHint': 'Rilascia i file qui',
      'readyToUploadMessage': 'Pronto per caricare',
      'uploadSuccessMessage': 'Caricamento completato',
      'uploadFailedMessage': 'Caricamento fallito',
      'inProgress': 'Caricamento in corso',
      'invalidMaxFileSize': 'Dimensione del file superiore al limite massimo',
      'remove': 'Rimuovi',
      'cancel': 'Annulla',
      'delete': 'Elimina',
    },
    'grid': {
      'EmptyRecord': 'Non ci sono record da visualizzare',
      'UnGroup': 'Clicca qui per non raggruppare',
      'EmptyDataSourceError': 'Si è verificato un errore',
      'Item': 'Elemento',
      'Items': 'Elementi'
    },
    'pager': {
      'currentPageInfo': '{0} di {1} pagine',
      'totalItemsInfo': '({0} elementi)',
      'firstPageTooltip': 'Prima pagina',
      'lastPageTooltip': 'Ultima pagina',
      'nextPageTooltip': 'Prossima pagina',
      'previousPageTooltip': 'Pagina precedente',
      'nextPagerTooltip': 'Prossimo pager',
      'previousPagerTooltip': 'Pager precedente',
    }
  }
});

const container = document.getElementById('root')!;
const root = createRoot(container);

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#000000',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#ffffff',
    },
    warning: {
      main: '#FFCE00'
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
}, itIT);

root.render(
  // <React.StrictMode>
    <ThemeProvider
      theme={theme}>
      <AppRoutes/>
    </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
