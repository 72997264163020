import { User } from "../../models";
import httpService from "../utils/http.service";

class AuthService {

  Http = httpService;

  user: any = {};

  async login(data: any) {
    this.user = data;
    localStorage.setItem("user", JSON.stringify(data));
  }

  async logout() {
    this.user = {};
    localStorage.removeItem("user");
    await this.checkUser();
    Promise.resolve(this.user);
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) {
      this.user = JSON.parse(userStr);
      return this.user;
    }
    return null;
  }

  async checkUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      const user = this.getCurrentUser();
      if (!user) {
        this.Http.rest({
          type: "get",
          path: "user/check",
        }).then((res) => {
          if (res)  {
            this.user = res;
            localStorage.setItem("user", JSON.stringify(res));
          }
          resolve(res);
        })
      } else {
        resolve( user );
      }
    });
  }
}
export default new AuthService();
