import "./b-button.scss";
import React from "react";

import { Button } from "@mui/material";

export enum ButtonType {
  PRIMARY = 1,
  SECONDARY = 2,
  TERTIARY = 3,
  SUBMIT = 10,
  SUBMIT_TERTIARY = 11,
}

export const BButton = (props: { className?: string, children?: any, type: ButtonType, text?: string, padding?: string, color?: 'primary' | 'secondary' | 'warning', background?: string, fontSize?: string, disabled?: boolean, href?: string, target?: string, uppercase?: boolean, minWidth?: string, click?: () => void}) => {

  let hrefData = {};
  if (props.href) {
    hrefData = {
      href: props.href,
      target: props.target ?? '_self',
    }
  }

  return (
    <React.Fragment>
      {props.type === ButtonType.PRIMARY &&
        <Button
          className={props.className}
          variant="contained"
          color={props.color ?? 'secondary'}
          {...hrefData}
          disabled={props.disabled}
          onClick={props.click}
          sx={{
            borderRadius: '25px',
            padding: '12px 30px',
            minWidth: props.minWidth ? props.minWidth : 'unset',
            textTransform: props.uppercase ? 'uppercase' : 'none',
          }}>
          {props.text ?? props.children}
        </Button>
      }
      {
        props.type === ButtonType.SECONDARY &&
        <Button
          className={props.className}
          variant="contained"
          color={props.color ?? 'primary'}
          {...hrefData}
          disabled={props.disabled}
          onClick={props.click}
          sx={{
            width: '100%',
            padding: '1rem',
            fontSize: '20px',
            fontWeight: 'bold',
            textTransform: props.uppercase ? 'uppercase' : 'none',
            backgroundColor: props.background ?? 'primary.main',
            '&.Mui-disabled': {
                backgroundColor: props.background ?? 'primary.main',
                opacity: 0.5,
            },
          }}
        >
          {props.text ?? props.children}
        </Button>
      }
      {
        props.type === ButtonType.TERTIARY &&
        <Button
          className={props.className}
          variant="contained"
          color={props.color ?? 'primary'}
          {...hrefData}
          disabled={props.disabled}
          onClick={props.click}
          // size="small"
          sx={{
            width: 'auto',
            padding: props.padding ?? '10px 20px',
            fontSize: props.fontSize ?? '15px',
            backgroundColor: props.background ?? 'primary.main',
            minWidth: props.minWidth ? props.minWidth : 'unset',
            textTransform: props.uppercase ? 'uppercase' : 'none',
            '&.Mui-disabled': {
              backgroundColor: props.background ?? 'primary.main',
              opacity: 0.5,
            },
          }}
        >
          {props.text ?? props.children}
        </Button>
      }

      {
        props.type === ButtonType.SUBMIT &&
        <Button
          className={props.className}
          type="submit"
          variant="contained"
          color={props.color ?? 'primary'}
          {...hrefData}
          disabled={props.disabled}
          onClick={props.click}
          sx={{
            width: '100%',
            padding: '1rem',
            fontSize: '20px',
            fontWeight: 'bold',
            color: props.color ?? 'primary',
            backgroundColor: props.background ?? 'primary.main',
            textTransform: props.uppercase ? 'uppercase' : 'none',
            '&.Mui-disabled': {
              backgroundColor: props.background ?? 'primary.main',
              opacity: 0.5,
            },
          }}
        >
          {props.text ?? props.children}
        </Button>
      }

      {
        props.type === ButtonType.SUBMIT_TERTIARY &&
        <Button
          className={props.className}
          type="submit"
          variant="contained"
          color={props.color ?? 'primary'}
          {...hrefData}
          disabled={props.disabled}
          onClick={props.click}
          // size="small"
          sx={{
            width: 'auto',
            padding: '10px 20px',
            fontSize: '15px',
            backgroundColor: props.background ?? 'primary.main',
            minWidth: props.minWidth ? props.minWidth : 'unset',
            textTransform: props.uppercase ? 'uppercase' : 'none',
            '&.Mui-disabled': {
              backgroundColor: props.background ?? 'primary.main',
              opacity: 0.5,
            },
          }}
        >
          {props.text ?? props.children}
        </Button>
      }
    </React.Fragment>
  );
}
