import "./qty-picker.scss";
import React from "react";

import {Box, Grid, IconButton, Typography} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';


export const QtyPicker = (props: { qty: number, disabled?: boolean, remove?: () => void, add?: () => void }) => {

    return (
        <Grid container spacing={0.4}>
            <Grid item xs={4} justifyContent="end">
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'secondary.main',
                    height: '100%',
                    width: '100%'
                }}>
                    <IconButton onClick={props.remove} disabled={props.disabled}>
                        <RemoveIcon fontSize="small"></RemoveIcon>
                    </IconButton>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'secondary.main',
                    height: '100%',
                    width: '100%'
                }}>
                    <Typography variant="body2">{props.qty}</Typography>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'secondary.main',
                    height: '100%',
                    width: '100%'
                }}>
                    <IconButton onClick={props.add} disabled={props.disabled}>
                        <AddIcon fontSize="small"></AddIcon>
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    );
}
