import "./color-picker.scss";
import React from "react";

import { Box, Button } from "@mui/material";

export const ColorPicker = (props: { colors: Array<any>, selected: number, loading: boolean, click: (id: number) => void }) => {

  return (
    <React.Fragment>
      {props.colors.map((color: any) => {
        return (
          <Box sx={{
            display: 'inline-flex',
            flexDirection: 'column',
            width: 'fit-content',
            alignItems: 'center',
            marginRight: '10px',
            marginBottom: '10px',
          }}>
            <Button
              key={color.id}
              disabled={props.loading}
              onClick={() => props.click(color.id)}
              sx={{
                padding: '0',
                border: props.selected === color.id ? '2px solid #FFCE00' : '',
                opacity: !props.loading ? 1 : 0.5,
              }}>
                <img width="60px" src={color.images?.[0]} alt="" />
            </Button>
            <span style={{
              marginTop: 1,
              color: props.selected === color.id ? '#FFCE00' : '#fff',
            }}>
              {color.valore}
            </span>
          </Box>
        );
      })}
    </React.Fragment>
  );
}
