import "./img-content.scss";

import { Button, Grid, Typography, useTheme } from "@mui/material";
import { BButton, ButtonType } from "../b-button/b-button";

export const ImgContent = (props: { side: 'normal' | 'reverse', image: string, title: string, text: string, btnText?: string, btnUrl?: string }) => {

  const theme = useTheme();

  return (
    <Grid container p={'40px'} bgcolor="primary.main" flexDirection={props.side === 'normal' ? 'row' : 'row-reverse'}>
      <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent={'center'}>
        <Typography
          variant="h2"
          sx={{
            textTransform: 'uppercase',
            fontWeight: '600',
            color: 'secondary.main',
            [theme.breakpoints.down('sm')]: {
              fontSize: '2rem',
              mb: 2
            },
          }}>
          {props.title}
        </Typography>
        <Typography
          variant="body1"
          color={'secondary.main'}
        >
          {props.text}
        </Typography>
        {props.btnText && 
          <BButton type={ButtonType.TERTIARY} background={'#fff'} color={'secondary'} href={props.btnUrl}>{props.btnText}</BButton>
        }
        <Grid item container mb={10}>
          <Grid item xs={6} sx={{
            display: { xs: 'inherit', sm: 'none' }
          }}>
            <img src={props.image} alt="" width="100%" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} sx={{
        display: { xs: 'none', md: 'inherit' }
      }}>
        <img src={props.image} width={'100%'} alt="" />
      </Grid>
    </Grid>
  );
}