import "./icon-text.scss";

import { Box, Icon, Typography } from "@mui/material";

export const IconText = (props: { color?: string, icon: string, title: string, text: string }) => {

  const color = props.color ? props.color : "secondary.main";

  return (
    <Box sx={{
      mt: 5,
      display: 'flex',
      alignItems: 'center'
    }}>
      <Icon fontSize="large" sx={{
        color
      }}>{props.icon}</Icon>
      <Box ml={2}>
        <Typography variant="h5" sx={{
          color,
          fontWeight: 800
        }}>
          {props.title}
        </Typography>
        <Typography variant="body2" color={color}>
          {props.text}
        </Typography>
      </Box>
    </Box>
  );
}