import "./register-form.scss";
import React, { useContext, useEffect } from "react";
import { BrowserHistory } from "history";
import mainService from "../../services/utils/main.service";
import authService from "../../services/authentication/auth.service";
import { Box, Typography, useTheme } from "@mui/material";
import { FormContainer, TextFieldElement, PasswordElement, CheckboxElement } from "react-hook-form-mui";
import { BButton, ButtonType } from "../../components/b-button/b-button";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReCaptcha from "@matt-block/react-recaptcha-v2";
import { SharedContext } from "../../components/routes/routes";
import httpService from "../../services/utils/http.service";

export const RegisterForm = (props: {bcode: string}) => {

  const navigate = useNavigate();
  const theme = useTheme();
  const searchContext = useContext(SharedContext);
  const {
    openModal,
    user,
    setUser,
    getCart
  } = searchContext;

  const [searchParams, setSearchParams] = useSearchParams();
  const [bcode, setBcode] = React.useState(searchParams.get('bcode') ?? null);

  useEffect(() => {
    console.log(props.bcode);
    if (props.bcode != null) {
      setBcode(props.bcode);
      formContext.setValue('bcode', props.bcode);
      localStorage.setItem('bcode', props.bcode);
    }
  }, [props.bcode]);

  useEffect(() => {
    if (bcode != null) {
      if (user?.id) {
        const Auth = authService;
        Auth.logout();
      }
      localStorage.setItem('bcode', bcode);
    }
  });

  const [recaptcha, setRecaptcha] = React.useState(false);

  const formContext = useForm<{
    nome: string,
    cognome: string,
    email: string,
    password: string,
    password2: string,
    newsletter: boolean,
    rules: boolean,
    bcode: string,
    'g-recaptcha-response': string
  }>({
    defaultValues: {
      bcode: bcode ?? ''
    }
  })

  const validation = {
    required: 'Campo obbligatorio'
  };

  const Main = mainService;
  const Auth = authService;
  Main.hideLoader();

  const handleRegister = (data: any) => {
    if (!data['g-recaptcha-response']) {
      openModal('Completa il captcha per continuare');
      return;
    }
    if (localStorage.getItem('bcode') != null) {
      data.bcode = localStorage.getItem('bcode');
    }
    data.old_user = user.id;
    httpService.rest({
      type: "put",
      path: "user/register",
      body: data,
    }).then(async (res) => {
      openModal("La tua registrazione è stata completata con successo. Benvenuto nel mondo BLOR", "Registrazione completata.");
      Auth.login(res);
      setUser(res);
      getCart();
      localStorage.removeItem('bcode');
      if (searchParams.get('returnUrl') != null) {
        navigate(searchParams.get('returnUrl') as string);
      } else {
        navigate("/account?init=1");
      }
    }, (err) => {
      formContext.setValue('password', '');
      openModal(err.data);
    });
  }

  return (
    <React.Fragment>
      <Box sx={{
        marginTop: '20px',
        '& .MuiTextField-root.half': {
          mb: 3,
          width: '50%',
        },
        '& .MuiTextField-root.full': {
          mb: 3,
          width: '100%',
        },
        '.MuiCheckbox-root': {
          color: 'white'
        },
        backgroundColor: 'primary.main',
        [theme.breakpoints.up('sm')]: {
          paddingX: '25%'
        },
        [theme.breakpoints.down('sm')]: {
          paddingX: '5%'
        },
        color: 'white',
        py: 2
      }}>
        <FormContainer
          formContext={formContext}
          onSuccess={handleRegister}
        >
          <TextFieldElement
            required
            name="nome"
            label="Nome"
            className="full"
            autoComplete="given-name"
            validation={validation}
            variant="filled"
            sx={{
              backgroundColor: 'white'
            }}
          />
          <TextFieldElement
            required
            name="cognome"
            label="Cognome"
            className="full"
            validation={validation}
            variant="filled"
            sx={{
              backgroundColor: 'white'
            }}
          />
          <TextFieldElement
            required
            name="email"
            label="Email"
            className="full"
            validation={validation}
            variant="filled"
            sx={{
              backgroundColor: 'white'
            }}
          />
          <PasswordElement
            required
            name="password"
            label="Password"
            className="full"
            validation={validation}
            variant="filled"
            sx={{
              backgroundColor: 'white'
            }}
          />
          <TextFieldElement
            name="bcode"
            label="Codice affiliato (non obbligatorio)"
            className="full"
            variant="filled"
            disabled={bcode != null}
            sx={{
              backgroundColor: 'white'
            }}
            helperText="Inserisci il codice della persona che ti ha invitato"
          />

          <ReCaptcha
            siteKey="6LeyCVEfAAAAAJdQtaGB_DCLUq2zbainH8Ne_tFJ"
            theme="light"
            size="normal"
            onSuccess={(captcha) => {
              setRecaptcha(true);
              formContext.setValue('g-recaptcha-response', captcha);
            }}
            onExpire={() => {
              setRecaptcha(false);
              formContext.setValue('g-recaptcha-response', '');
            }}
            onError={() => openModal("Errore di comunicazione con il server di reCaptcha")}
          />
          {bcode && (
            <Box mt={5}>
              <Typography typography={'body1'}>
                Codice affiliato applicato
              </Typography>
            </Box>
          )}

          <div className="full" style={{ marginLeft: '1%' }}>
            <CheckboxElement
              name="newsletter"
              label="Accetto di ricevere email da Blor sulle novità"
              color="secondary" />
          </div>

          <div className="full" style={{ marginLeft: '1%' }}>
            <CheckboxElement
              name="rules"
              required={true}
              validation={validation}
              label="Accetto le regole di condotta"
              color="secondary" />
            <div>
              <a className="color-secondary text-underline" href="https://blor.fra1.cdn.digitaloceanspaces.com/website/REGOLE%20DI%20CONDOTTA%20BLOR%2024-11-22.pdf" target={'_blank'} rel="noreferrer">Consulta le regole di condotta</a>
            </div>
          </div>
          <div className="full" style={{ marginLeft: '1%' }}>
            <CheckboxElement
              name="privacy"
              required={true}
              validation={validation}
              label="Accetto la privacy policy"
              color="secondary" />
            <div>
              <a className="color-secondary text-underline" href="https://www.iubenda.com/privacy-policy/75719538" target={'_blank'} rel="noreferrer">Consulta la privacy policy</a>
            </div>
          </div>
          <div className="full" style={{ marginLeft: '1%' }}>
            <CheckboxElement
              name="terms"
              required={true}
              validation={validation}
              label="Accetto i termini e le condizioni di utilizzo"
              color="secondary" />
            <div>
              <a className="color-secondary text-underline" href="https://blor.fra1.cdn.digitaloceanspaces.com/website/docs/TERMINI%20E%20CONDIZIONI%20BLOR.pdf" target={'_blank'} rel="noreferrer">Consulta i termini e le condizioni d'uso</a>
            </div>
          </div>
          <Box mt={5}>
            <BButton type={ButtonType.SUBMIT} color="secondary" background="primary">Registrati</BButton>
          </Box>
        </FormContainer>
      </Box>
    </React.Fragment>
  )
}
