import "./social-logins.scss";
import React, { useContext } from "react";

import { Box, Button, Grid } from "@mui/material";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import FacebookLogin, { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import httpService from "../../services/utils/http.service";
import authService from "../../services/authentication/auth.service";
import { SharedContext } from "../routes/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import mainService from "../../services/utils/main.service";
import { BButton } from "../b-button/b-button";

export enum ButtonType {
  PRIMARY = 1,
  SECONDARY = 2,
  TERTIARY = 3,
  SUBMIT = 10,
  SUBMIT_TERTIARY = 11,
}

export const SocialLogins = (props: any) => {

  const Auth = authService;
  const Main = mainService;
  const navigate = useNavigate();
  const searchContext = useContext(SharedContext);
  const {
    openModal,
    user,
    setUser,
    getCart
  } = searchContext;

  const [searchParams, setSearchParams] = useSearchParams();

  const handleLogin = (idprovider: number, data: any) => {
    Main.showLoader();
    httpService.rest({
      type: "post",
      path: `login/social/${idprovider}`,
      body: {
        ...data,
        bcode: props.bcode ?? '',
        old_user_id: user?.id
      },
    }).then(async (res) => {
      Main.hideLoader();
      Auth.login(res);
      setUser(res);
      getCart();
      if (searchParams.get('returnUrl') != null) {
        navigate(searchParams.get('returnUrl') as string);
      } else {
        navigate("/");
      }
    }, (err) => {
      Main.hideLoader();
      openModal(err.data);
    });
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} my={5}>
        <Grid item xs={6} display={'flex'} justifyContent={'center'}>
          <FacebookLogin
            appId="494449704577944"
            initParams={{
              version: 'v10.0',
            }}
            language="it_IT"
            loginOptions={{
              ignoreSdkError: true,
            }}
            style={{
              color: '#000',
              fontSize: '14px',
              border: 'none',
              borderRadius: '4px',
            }}
            useCustomerChat={true}
            render={({ onClick, logout }) => (
              <BButton type={ButtonType.TERTIARY} background="#4267B2" padding={'6px 14px'} fontSize="14px" color="primary" click={onClick}>Accedi con Facebook</BButton>
            )}
            onSuccess={(response) => {
              // Access token
              // console.log(response.accessToken);
              // console.log('Login Success!', response);
              handleLogin(1, response);
            }}
            onFail={(error) => {
              console.log('Login Failed!', error);
            }}
            onProfileSuccess={(response) => {
              // console.log('Get Profile Success!', response);
            }}
          />
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'center'}>
          <Box>
            <GoogleLogin
              size="medium"
              onSuccess={credentialResponse => {
                // Access token
                handleLogin(2, credentialResponse);
                // console.log(credentialResponse.credential);
                // console.log(credentialResponse);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
              />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}