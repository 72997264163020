import "./contact-form-withdrawal.scss";
import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import ReCaptcha from "@matt-block/react-recaptcha-v2";
import { BButton, ButtonType } from "../b-button/b-button";
import httpService from "../../services/utils/http.service";


export const ContactFormWithdrawal = (props: { formId: number, additionalData?: any, version?: 'dark' | 'white', complete: (data: any) => void }) => {

  const [recaptcha, setRecaptcha] = React.useState(false);

  const formContext = useForm<{
    nome: string,
    cognome: string,
    email: string,
    cellulare: string,
    bpoint: number,
    'g-recaptcha-response': string
  }>();

  const validation = {
    required: 'Campo obbligatorio'
  };

  const handleSubmit = (data: any) => {
    if (props.additionalData) {
      data = { ...data, ...props.additionalData };
    }
    data = { ...data, form_id: props.formId };
    props.complete(data);
    formContext.reset();
  }

  const Http = httpService;
  useEffect(() => {
    Http.rest({
      path: `user`,
      type: 'get'
    }).then((res) => {
      formContext.setValue('nome', res.nome);
      formContext.setValue('cognome', res.cognome);
      formContext.setValue('email', res.email);
      formContext.setValue('cellulare', res.telefono);
      formContext.setValue('bpoint', res.bpoints);
    }).catch((err) => {
    });
  });

  return (
    <React.Fragment>
      <Box sx={{
        width: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        '& .MuiTextField-root.half': {
          m: '1%',
          width: '48%',
          backgroundColor: '#fff',
        },
        '& .MuiTextField-root.full': {
          m: '1%',
          width: '98%',
          backgroundColor: '#fff',
        },
        my: 4,
        mx: 'auto'
      }}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          mb: 4
        }}>
          <img className="mr-5" src="/bp.png" height={50} alt="" />
          <Typography variant="h3">
            Converti i tuoi B-Point in euro!
          </Typography>
        </Box>

        <FormContainer
          formContext={formContext}
          onSuccess={handleSubmit}
        >
          <TextFieldElement
            required
            name="nome"
            label="Nome"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="cognome"
            label="Cognome"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="cellulare"
            label="Telefono"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="email"
            label="Email"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="bpoint"
            label="B-Point"
            className="full"
            variant="filled"
            type={'number'}
            InputProps={{
              inputProps: {
                max: formContext.getValues('bpoint')
              }
            }}
            validation={validation}
          />
          <Box mb={4}></Box>
          <ReCaptcha
            siteKey="6LeyCVEfAAAAAJdQtaGB_DCLUq2zbainH8Ne_tFJ"
            theme="light"
            size="normal"
            onSuccess={(captcha) => {
              setRecaptcha(true);
              formContext.setValue('g-recaptcha-response', captcha);
            }}
            onExpire={() => {
              setRecaptcha(false);
              formContext.setValue('g-recaptcha-response', '');
            }}
            onError={() => console.log('errore')}
          />
          <Button type="submit" disabled={!recaptcha} sx={{
            mt: 4,
            color: props.version === 'dark' ? '#fff' : 'primary.main',
            backgroundColor: props.version === 'dark' ? 'primary.main' : '#fff',
            width: '100%',
            minHeight: '48px',
            ':hover': {
              color: props.version === 'dark' ? '#fff' : 'primary.main',
              backgroundColor: props.version === 'dark' ? 'primary.main' : '#fff',
            },
            '&.Mui-disabled': {
              color: props.version === 'dark' ? '#fff' : 'primary.main',
              backgroundColor: props.version === 'dark' ? 'primary.main' : '#fff',
            }
          }}>Invia</Button>
        </FormContainer>
      </Box>
    </React.Fragment>
  );
}