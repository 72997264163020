import "./img-text.scss";
import React from "react";

import { Grid, Icon, Typography } from "@mui/material";

export const ImageText = (props: { text?: string, image?: string, icon?: string, iconObj?: JSX.Element, children?: any }) => {

  return (
    <Grid item xs={12} sm sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      {props.image && <img height={40} src={props.image} alt={props.text} />}
      {props.icon && <Icon fontSize="large">{props.icon}</Icon>}
      {props.iconObj && props.iconObj}
      <Typography
        variant="h6"
        color={'secondary.main'}
        textAlign="center"
        my={2}
        lineHeight={1.2}
      >
        {props.children ?? props.text}
      </Typography>
    </Grid>
  );
}