import "./affiliate-form.scss";
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { DatePickerElement, FormContainer, RadioButtonGroup, TextFieldElement } from "react-hook-form-mui";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { it } from 'date-fns/locale';

export const AffiliateForm = (props: { data: any, version?: 'dark' | 'white', disabled?: boolean, complete: (data: any) => void }) => {

  const [recaptcha, setRecaptcha] = React.useState(false);

  const formContext = useForm<{
    nome: string,
    cognome: string,
    email: string,
    localita_nascita: string,
    provincia_nascita: string,
    data_nascita: string,
    localita_residenza: string,
    provincia_residenza: string,
    indirizzo_residenza: string,
    civico_residenza: string,
    cap_residenza: string,
    codice_fiscale: string,
    partita_iva: string,
    telefono: string,
    cellulare: string,
    pec: string,
    iban: string,
    doc_text: string,
    avere_non: string,
    luogo: string,
    data: string
  }>({
    defaultValues: {
      nome: props.data.nome ?? '',
      cognome: props.data.cognome ?? '',
      email: props.data.email ?? '',
      avere_non: '2'
    }
  });

  const validation = {
    required: 'Campo obbligatorio'
  };

  const options = [
    {
      id: '1',
      label: 'Avere'
    },
    {
      id: '2',
      label: 'Non avere'
    }
  ];

  const handleSubmit = (data: any) => {
    props.complete(data);
  }

  return (
    <React.Fragment>
      <Box sx={{
        width: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        '& .MuiTextField-root.half': {
          m: '1%',
          width: '48%',
          backgroundColor: '#fff',
        },
        '& .MuiTextField-root.full': {
          m: '1%',
          width: '98%',
          backgroundColor: '#fff',
        },
        my: 4,
        mx: 'auto'
      }}>
        <Typography variant="h3" mb={4} fontWeight={'bold'}>
          Diventa Affiliato Incaricato BLOR
        </Typography>

        <FormContainer
          formContext={formContext}
          onSuccess={handleSubmit}
        >
          <TextFieldElement
            required
            name="nome"
            label="Nome"
            className="half"
            variant="filled"
            InputProps={{
              readOnly: true,
            }}
            validation={validation}
          />
          <TextFieldElement
            required
            name="cognome"
            label="Cognome"
            className="half"
            variant="filled"
            InputProps={{
              readOnly: true,
            }}
            validation={validation}
          />
          <TextFieldElement
            required
            name="email"
            label="Email"
            className="half"
            variant="filled"
            InputProps={{
              readOnly: true,
            }}
            validation={validation}
          />
          <br/>
          <TextFieldElement
            required
            name="localita_nascita"
            label="Città di nascita"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="provincia_nascita"
            label="Provincia di nascita"
            className="half"
            variant="filled"
            validation={validation}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
            <Box>
              <DatePickerElement
                required
                name="data_nascita"
                label="Data di nascita"
                className="half"
                validation={validation}
                inputProps={{
                  variant: 'filled'
                }}
              />
            </Box>
          </LocalizationProvider>
          <TextFieldElement
            required
            name="localita_residenza"
            label="Città di residenza"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="provincia_residenza"
            label="Provincia di residenza"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="indirizzo_residenza"
            label="Indirizzo di residenza"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="civico_residenza"
            label="Civico"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="cap_residenza"
            label="CAP"
            className="half"
            variant="filled"
            validation={validation}
          />
          <br/>
          <TextFieldElement
            required
            name="codice_fiscale"
            label="Codice fiscale"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            name="partita_iva"
            label="Partita IVA"
            className="half"
            variant="filled"
          />
          <TextFieldElement
            name="telefono"
            label="Telefono"
            className="half"
            variant="filled"
          />
          <TextFieldElement
            required
            name="cellulare"
            label="Cellulare"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            name="pec"
            label="PEC o SDI"
            className="half"
            variant="filled"
          />
          <TextFieldElement
            required
            name="iban"
            label="IBAN"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="doc_text"
            label="Documento di identità (tipologia)"
            className="full"
            variant="filled"
            validation={validation}
          />

          <Box className="full" m={'1%'}>
            <RadioButtonGroup
              label="Dichiaro di AVERE superato un reddito complessivo netto, derivante dall'attività di incaricato alle vendite, di €5.000 come descritto al comma 5."
              name='avere_non'
              required={true}
              valueKey="id"
              options={options}
            />
          </Box>

          <Box mb={4}></Box>
          Dichiaro di avere compiuto <b>18 anni di età</b> e di <b>non essere un dipendente pubblico</b> a tempo pieno e part-time con orario superiore al 50% della prestazione lavorativa a tempo pieno (salva specifica autorizzazione rilasciata dall’ente pubblico e salva diversa disposizione di legge intervenuta nel corso del rapporto).
          <Box mb={4}></Box>
          <Button type="submit" disabled={props.disabled} sx={{
            mt: 4,
            color: props.version === 'dark' ? '#fff' : 'primary.main',
            backgroundColor: props.version === 'dark' ? 'primary.main' : '#fff',
            width: '100%',
            minHeight: '48px',
            ':hover': {
              color: props.version === 'dark' ? '#fff' : 'primary.main',
              backgroundColor: props.version === 'dark' ? 'primary.main' : '#fff',
            },
            '&.Mui-disabled': {
              color: props.version === 'dark' ? '#fff' : 'primary.main',
              backgroundColor: props.version === 'dark' ? 'primary.main' : '#fff',
            }
          }}>Genera contratto</Button>
        </FormContainer>
      </Box>
    </React.Fragment>
  );
}