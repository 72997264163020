import "./size-picker.scss";
import React from "react";

import { Button } from "@mui/material";

export const SizePicker = (props: { sizes: Array<any>, selected: number, loading: boolean, click: (id: string) => void }) => {

  return (
    <React.Fragment>
      {props.sizes.map((size: any) => {
        return (
          <Button
            key={size.id}
            onClick={() => {
              if (props.selected !== size.value)  {
                props.click(size)
              }
            }}
            disabled={props.loading || size.quantita <= 0}
            sx={{
              padding: 'unset 5px',
              marginX: '5px',
              marginBottom: '10px',
              fontWeight: 'bold',
              borderRadius: '6px',
              background: props.selected === size.value ? '#FFCE00' : '#f7f8fa',
              color: props.selected === size.value ? '#000' : '#777',
              minWidth: '40px',
              minHeight: '40px',
              '&:hover': {
                background: '#FFCE00',
                color: '#000',
              },
              '&:disabled': {
                color: props.selected === size.value ? '#000' : '#ccc',
              }
            }}>
              {size.text}
          </Button>
        );
      })}
    </React.Fragment>
  );
}
