import "./contact-form.scss";
import React from "react";
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { CheckboxButtonGroup, FormContainer, TextFieldElement } from "react-hook-form-mui";
import ReCaptcha from "@matt-block/react-recaptcha-v2";
import { BButton, ButtonType } from "../b-button/b-button";


export const ContactForm = (props: { formId: string | number | null, additionalData?: any, version?: 'dark' | 'white', complete: (data: any) => void }) => {

  const [recaptcha, setRecaptcha] = React.useState(false);

  const theme = useTheme();
  const formContext = useForm<{
    nome: string,
    cognome: string,
    email: string,
    cellulare: string,
    messaggio: string,
    'g-recaptcha-response': string
  }>();

  const validation = {
    required: 'Campo obbligatorio'
  };

  const handleSubmit = (data: any) => {
    if (props.additionalData) {
      data = { ...data, ...props.additionalData };
    }
    data = { ...data, form_id: props.formId };
    props.complete(data);
    formContext.reset();
  }

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <React.Fragment>
      <Box sx={{
        width: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        '& .MuiTextField-root.half': {
          m: '1%',
          width: '48%',
          backgroundColor: '#fff',
        },
        '& .MuiTextField-root.full': {
          m: '1%',
          width: '98%',
          backgroundColor: '#fff',
        },
        my: 4,
        mx: 'auto'
      }}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{
            position: 'relative',
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: '600',
            my: 4
          }}>Invia la tua richiesta</Typography>
        </Grid>
        {/* <Typography variant="h3" mb={4}>
          Invia la tua richiesta
        </Typography> */}

        <FormContainer
          formContext={formContext}
          onSuccess={handleSubmit}
        >
          <TextFieldElement
            required
            name="nome"
            label="Nome"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="cognome"
            label="Cognome"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="cellulare"
            label="Telefono"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="email"
            label="Email"
            className="half"
            variant="filled"
            validation={validation}
          />
          <TextFieldElement
            required
            name="messaggio"
            label="Messaggio"
            className="full"
            variant="filled"
            multiline
            rows={4}
            validation={validation}
          />
          <FormControlLabel control={<Checkbox
            color="secondary"
            checked={checked}
            onChange={handleChange}
            sx={{
              color: props.version === 'dark' ? '#000' : '#fff',
              '&.Mui-checked': {
                color: props.version === 'dark' ? '#000' : '#fff',
              },
          }} />} label="*Ho preso visione dell'Informativa Privacy e acconsento al trattamento dei dati personali." />

          <Box mb={4}></Box>
          <ReCaptcha
            siteKey="6LeyCVEfAAAAAJdQtaGB_DCLUq2zbainH8Ne_tFJ"
            theme="light"
            size="normal"
            onSuccess={(captcha) => {
              setRecaptcha(true);
              formContext.setValue('g-recaptcha-response', captcha);
            }}
            onExpire={() => {
              setRecaptcha(false);
              formContext.setValue('g-recaptcha-response', '');
            }}
            onError={() => console.log('errore')}
          />
          <Button type="submit" disabled={!recaptcha || !checked} sx={{
            mt: 4,
            color: props.version === 'dark' ? '#fff' : 'primary.main',
            backgroundColor: props.version === 'dark' ? 'primary.main' : '#fff',
            width: '100%',
            minHeight: '48px',
            ':hover': {
              color: props.version === 'dark' ? '#fff' : 'primary.main',
              backgroundColor: props.version === 'dark' ? 'primary.main' : '#fff',
            },
            '&.Mui-disabled': {
              color: props.version === 'dark' ? '#fff' : 'primary.main',
              backgroundColor: props.version === 'dark' ? 'primary.main' : '#fff',
            }
          }}>Invia</Button>
        </FormContainer>
      </Box>
    </React.Fragment>
  );
}